import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const videos = [
  {
    id: 'video1',
    title: '"PRESTA" | Instru Afro Love Guitare',
    url: 'https://www.youtube.com/embed/sg04fsmyo6E?si=LhJ4XjebwsfnaYrN', // Remplacez par l'ID de votre vidéo YouTube
  },
  {
    id: 'video2',
    title: '"Got Yust" | Afrobeat Instrumental 2024',
    url: 'https://www.youtube.com/embed/gYlpyDTGp5I?si=_azEsVtz4x5Ozrp7', // Remplacez par l'ID de votre vidéo YouTube
  },
  {
    id: 'video3',
    title: '"MONA " | AfroBeat / AfroRnB | Instru Love',
    url: 'https://www.youtube.com/embed/3IuLmyqdTDo?si=1I_I0Aa32MOjhD-b', // Remplacez par l'ID de votre vidéo YouTube
  },
];

const VideoItem = ({ video }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // L'animation se déclenche lorsque 20% de l'élément est visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6, ease: 'easeOut' }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="relative w-full p-4 md:w-1/2 lg:w-1/3"
    >
      <div className="overflow-hidden bg-gray-200 rounded-lg shadow-md aspect-w-16 aspect-h-9">
        <iframe
          className="w-full h-full"
          src={video.url}
          title={video.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <h3 className="mt-4 text-xl font-semibold text-center text-gray-800">{video.title}</h3>
    </motion.div>
  );
};

VideoItem.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

function Beats() {
  return (
    <section className="py-16 text-gray-900 bg-white md:py-28">
      <div className="container px-4 mx-auto md:px-8 lg:px-12">
        <div className="mb-12 text-center">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
            className="mb-6 text-4xl font-bold text-red-500"
          >
            Découvrez Mes Beats
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.1 }}
            className="text-xl text-gray-600"
          >
            Plongez dans l'univers musical qui fait vibrer votre âme. Chaque beat est une invitation à explorer de nouveaux horizons sonores.
          </motion.p>
        </div>

        <div className="flex flex-wrap justify-center -mx-4">
          {videos.map((video) => (
            <VideoItem key={video.id} video={video} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Beats;
