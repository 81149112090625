import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

const routes = [
  { name: "Accueil", href: "#home", isActive: true },
  { name: "À propos", href: "#about", isActive: false },
  { name: "Beats", href: "#beats", isActive: false },
  { name: "Témoignages", href: "#testimonials", isActive: false },
  { name: "Contact", href: "#contact", isActive: false },
];

// Mobile navigation menu
const MobileNavMenu = ({ routes, isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.ul
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="fixed inset-0 z-20 flex flex-col items-center justify-center h-screen gap-8 text-2xl bg-black font-body"
        id="navbar"
      >
        {routes.map((route, i) => (
          <li key={i} className="w-full text-center">
            <a
              className={`block w-full py-2 ${
                route.isActive
                  ? "opacity-100 text-red-500"
                  : "opacity-50 hover:opacity-100 hover:text-red-500"
              } text-white`}
              href={route.href}
            >
              {route.name}
            </a>
          </li>
        ))}
      </motion.ul>
    )}
  </AnimatePresence>
);

MobileNavMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

// Desktop navigation menu
const DesktopNavMenu = ({ routes }) => (
  <ul
    className="flex-row items-center justify-center hidden gap-6 text-base lg:flex font-body"
    id="navbar"
  >
    {routes.map((route, i) => (
      <li key={i}>
        <a
          className={`px-4 ${
            route.isActive
              ? "opacity-100 text-red-500"
              : "opacity-50 hover:opacity-100 hover:text-red-500"
          } text-white`}
          href={route.href}
        >
          {route.name}
        </a>
      </li>
    ))}
  </ul>
);

DesktopNavMenu.propTypes = {
  routes: PropTypes.array.isRequired,
};

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
      className={`fixed top-0 left-0 w-full z-50 px-6 py-4 backdrop-filter backdrop-blur-lg bg-opacity-60 text-white bg-[#0a0a0a] lg:px-8 ezy__nav1 font-body ${
        isSticky ? "shadow-lg" : ""
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <nav className="container flex items-center justify-between mx-auto">
        <a
          className="text-2xl italic font-medium text-red-500 lg:text-3xl font-logo"
          href="#home"
        >
          ILF A LA PROD
        </a>
        <button
          className="z-50 flex items-center justify-center w-10 h-10 lg:hidden"
          type="button"
          id="hamburger"
          onClick={toggleMenu}
        >
          <motion.div
            className="absolute h-0.5 w-7 bg-white"
            animate={isMenuOpen ? { rotate: 45, y: 0 } : { rotate: 0, y: -8 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div
            className="absolute h-0.5 w-7 bg-white"
            animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div
            className="absolute h-0.5 w-7 bg-white"
            animate={isMenuOpen ? { rotate: -45, y: 0 } : { rotate: 0, y: 8 }}
            transition={{ duration: 0.3 }}
          />
        </button>
        <MobileNavMenu routes={routes} isOpen={isMenuOpen} />
        <DesktopNavMenu routes={routes} />
      </nav>
    </motion.div>
  );
};

export default Navbar;
