import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import about1 from "./../../assets/img/about1.jpg";
import about2 from "./../../assets/img/about2.jpg";

const stories = [
  {
    title: "L'Histoire de ma Passion",
    description:
      "Depuis mon plus jeune âge, j'ai été captivé par les rythmes et les mélodies. Grandir à Brazzaville, au cœur du Congo, m'a permis d'explorer la richesse culturelle musicale de mon pays. Aujourd'hui, je combine cette richesse avec des influences modernes pour créer des beats uniques et puissants.",
    image: about1,
  },
  {
    title: "Mon Parcours en tant que Beatmaker",
    description:
      "Mon aventure dans la production musicale a commencé avec des outils simples, mais avec beaucoup de passion et de détermination. Au fil des années, j'ai perfectionné mon art pour produire des beats qui racontent des histoires et font vibrer les émotions. Que vous soyez artiste, rappeur ou chanteur, mes beats sont conçus pour sublimer votre voix.",
    image: about2,
  },
];

const StoryItem = ({ item, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Déclenche l'animation lorsque 20% de l'élément est visible
  });

  const { title, description, image } = item;

  // Définir les variantes d'animation pour Framer Motion
  const textVariants = {
    hidden: { opacity: 0, x: index % 2 === 0 ? 100 : -100 },
    visible: { opacity: 1, x: 0 },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <>
      <motion.div
        ref={ref}
        className={`col-span-12 md:col-span-5 ${
          index % 2 === 0
            ? "order-1 md:order-2 md:col-start-7"
            : "order-2 md:order-1 md:col-start-2"
        }`}
        variants={textVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
      >
        <div
          className={`flex flex-col justify-center ${
            index % 2 === 0 ? "lg:pl-14" : "lg:pr-14"
          }`}
        >
          <h4 className="mb-4 text-2xl font-bold text-red-500">{title}</h4>
          <p className="mb-0 text-base leading-relaxed text-justify text-gray-700 opacity-90 md:pr-6">
            {description}
          </p>
        </div>
      </motion.div>
      <motion.div
        className={`${
          index % 2 === 0
            ? "order-1 md:col-start-2"
            : "order-1 md:order-2 md:col-start-7"
        } col-span-12 md:col-span-5 mb-6 md:mb-0 mt-6 md:mt-0`}
        variants={imageVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
      >
        <div>
          <img
            src={image}
            alt={title}
            className="h-auto max-w-full rounded-2xl"
          />
        </div>
      </motion.div>
    </>
  );
};

StoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const AboutHome = () => {
  return (
    <section className="text-gray-900 bg-white py-14 md:py-24">
      <div className="container px-4">
        <div className="grid justify-center grid-cols-12 mb-12 text-center">
          <div className="col-span-12 md:col-span-8 md:col-start-3">
            <h2 className="mb-6 text-4xl font-bold leading-snug text-red-500 md:text-5xl md:leading-snug">
              À propos de Moi
            </h2>
            <p className="mb-4 text-xl text-gray-700 opacity-90">
              Je suis un beatmaker passionné basé à Brazzaville, Congo. Mon travail est le fruit de la fusion entre les traditions musicales congolaises et les tendances modernes, créant ainsi une expérience sonore unique.
            </p>
          </div>
        </div>

        {stories.map((item, i) => (
          <div
            className="grid items-center justify-center grid-cols-12 mt-12"
            key={i}
          >
            <StoryItem item={item} index={i + 1} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutHome;
