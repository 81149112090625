import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import fondarriere from './../../assets/img/fond.jpg'; // Assurez-vous que le chemin vers l'image est correct

const HeroHome = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div
      className="relative flex flex-col h-screen bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${fondarriere})`,
      }}
    >
      {/* Overlay pour rendre le texte plus lisible */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* Hero Section */}
      <header
        ref={ref}
        className="relative z-10 flex flex-col items-center justify-center flex-grow px-8 text-center"
      >
        <motion.h1
          className="mb-8 text-6xl font-extrabold text-white drop-shadow-lg"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={inView ? { scale: 1, opacity: 1 } : {}}
          transition={{ duration: 0.7, ease: "easeOut" }}
        >
          ILF A LA PROD
        </motion.h1>
        <motion.p
          className="max-w-2xl mb-12 text-lg text-gray-300"
          initial={{ x: -100, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : {}}
          transition={{ duration: 0.7, delay: 0.2, ease: "easeOut" }}
        >
          Plongez dans un univers sonore unique. Des beats conçus pour captiver l'essence de votre art.
          Travaillez avec un beatmaker passionné qui sait faire vibrer les émotions.
        </motion.p>
        <motion.a
          href="#contact"
          className="px-8 py-4 text-lg font-bold text-white transition-all duration-300 ease-in-out bg-red-600 rounded-full shadow-lg hover:bg-red-700"
          initial={{ y: 30, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
        >
          CONTACTEZ-MOI
        </motion.a>
      </header>
    </div>
  );
};

export default HeroHome;
