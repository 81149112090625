import React from 'react'
import Navbar from '../../components/Navbar'
import HeroHome from './Hero'
import AboutHome from './About'
import Beats from './Beats'

function Home() {
  return (
    <>
    <Navbar/>
    <HeroHome/>
    <AboutHome/>
    <Beats/>
    </>
  )
}

export default Home