import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Home from '../screens/home/Home';

function MainNavigation() {
  return (
    <Router>
        <Routes>
            <Route exact path='/' element={<Home/>} />
        </Routes>
    </Router>
  )
}

export default MainNavigation